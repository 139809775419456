<template>
    <div class="container">
        <div class="content-img">
            <div class="content-font">
                <p>二维码通行</p>
                <p>临时密码</p>
            </div>
        </div>
        <div class="content-info">
            <div class="head-font">访问信息
            </div>
            <div style="margin: 20px">
                <div class="info-font">
                    <span>被访人</span>
                    <span style="font-weight: bold;margin-top: 10px;">姓名</span>
                </div>
                <div class="info-font">
                    <p>访问时间</p>
                    <div style="font-weight: bold;display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
                        <span>2023-08-23 14:24</span>
                        <van-icon name="arrow" color="#9e9e9e21" size="30px"/>
                        <span>2023-08-24 14:24</span>
                    </div>
                </div>
                <div class="info-font">
                    <p>到访地址</p>
                    <div>
                        <span style="font-weight: bold;text-align: start;">湖南省长沙市岳麓区绿地广场</span>
                        <div style="display: flex;flex-direction: column;align-items: center;width: 55px;"
                             @click="handleClick">
                            <div style="background-color: #e85744;border-radius: 50%;width: 28px;height: 28px;padding: 10px 0 0 10px">
                                <van-icon name="location-o" color="#fff"/>
                            </div>
                            <span style="font-size: 12px">到这去</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <van-action-sheet v-model="showmore" description="请选择已安装APP" :actions="actions" @select="onSelect"/>

    </div>
</template>
<script>
    import {getInfo} from "../api/interface";
    import wx from 'weixin-js-sdk';
    import {Icon, ActionSheet} from 'vant';
    // import Vue from 'vue'
    // import {VueJsonp} from 'vue-jsonp'
    //
    // Vue.use(VueJsonp)

    export default {
        name: "locationMap",
        components: {
            [Icon.name]: Icon,
            [ActionSheet.name]: ActionSheet,
        },
        data() {
            return {
                showmore: false,
                actions: [{id: 1, name: '腾讯地图'}, {id: 2, name: '百度地图'}, {id: 3, name: '高德地图'}],
                latitude: "",
                longitude: ""
            }
        },
        mounted() {
            // this.getConfig()
        },
        methods: {
            handleClick() {
                this.showmore = true
            },
            onSelect(item) {
                console.log(item);
                // let longitude = "导航地址经度",
                //     latitude = "导航地址维度"
                let longitude = "108.374175",
                    latitude = "22.832448"
                let name = "湖南省长沙市岳麓区绿地中央广场";
                let url = "";
                const u = navigator.userAgent;
                const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
                if (isAndroid) {
                    switch (item.id) {
                        //下面是拼接url,不同系统以及不同地图都有不同的拼接字段
                        case 1:
                            //注意referer=xxx的xxx替换成你在腾讯地图开发平台申请的key
                            url = `qqmap://map/geocoder?coord=${latitude},${longitude}&referer=xxx`;
                            break;
                        case 2:
                            url = `baidumap://map/marker?location=${latitude},${longitude}&title=${name}&coord_type=gcj02&src=andr.baidu.openAPIdemo`;
                            break;
                        case 3:
                            url = `androidamap://viewMap?sourceApplication=appname&poiname=${name}&lat=${latitude}&lon=${longitude}&dev=0`;
                            break;
                        default:
                            break;
                    }
                    if (url != "") {
                        window.open(url, "_blank")
                    }
                } else if (isiOS) {
                    switch (item.id) {
                        case 1:
                            url = `qqmap://map/geocoder?coord=${latitude},${longitude}&referer=${name}`;
                            break;
                        case 2:
                            url = `baidumap://map/marker?location=${latitude},${longitude}&title=${name}&content=${name}&src=ios.baidu.openAPIdemo&coord_type=gcj02`;
                            break;
                        case 3:
                            url = `iosamap://viewMap?sourceApplication=applicationName&poiname=${name}&lat=${latitude}&lon=${longitude}&dev=0`;
                            break;
                        default:
                            break;
                    }
                    if (url != "") {
                        window.open(url, "_blank")
                    }
                }
            },
            //从微信jssdk获取当前定位
            getConfig() {
                let that = this
                getInfo(window.location.href.split('#')[0]).then((res) => {
                    wx.config({
                        beta: true,
                        debug: false,
                        appId: "wxa9aa498cc6b6b514",         // 必填，公众号的唯一标识，填自己的！
                        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
                        nonceStr: res.data.data.nonceStr,   // 必填，生成签名的随机串
                        signature: res.data.data.signature, // 必填，签名，见附录1
                        jsApiList: ['checkJsApi', 'getLocation', 'openLocation'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                    });

                    wx.checkJsApi({
                        jsApiList: ['getLocation'],
                        success: function (res) {
                            if (res.checkResult.getLocation == false) {
                                that.$toast('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！');
                                return;
                            }
                        },
                        fail: function (res) {
                            console.log('checkJsApi fail=' + JSON.stringify(res))
                        }
                    });

                    wx.ready(function () {
                        wx.getLocation({
                            type: "gcj02", //jssdk获取的坐标类型
                            success: function (res) {
                                console.log(res, 'success')
                                that.GCJPosition.lat = res.latitude //纬度
                                that.GCJPosition.lng = res.longitude //经度
                            },
                            cancel: function (res) {
                                console.log(res, 'cancel')
                            },
                            fail: function (res) {
                                console.log('fail', res)
                            }
                        });
                    })
                })
            }
            ,
        }
    }
</script>
<style lang="less">
    .guide-btn {
        text-align: center;
        background-color: #2c96f6;
        font-size: 24px;
        color: #fff;
        margin: 20px;
        padding: 20px;
    }

    .container {
        width: 100vw;
        height: 100vh;
        background: #f2f2f2;
        display: flex;
        flex-direction: column;
        font-size: 16px;
    }

    .content-img {
        background-size: contain;
        background-repeat: no-repeat;
        /* 把背景图片固定住 */
        background-attachment: fixed;
        width: 100%;
        height: 180px;
        background-image: url("../assets/img/carHead.png");

        .content-font {
            display: flex;
            flex-direction: row;

            p {
                margin: 10px;
                color: white;
            }
        }
    }

    .content-info {
        background-color: white;
        height: 100%;
        margin-top: 20px;

        .head-font {
            color: #8e8e8e;
            border-bottom: solid 1px #e4e4e4;
            padding: 10px;
            margin: 10px;
            text-align: left;
        }

        .info-font {
            display: flex;
            flex-direction: column;
            align-items: start;

            div {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }
    }

    .bottom-map {
        display: flex;
        flex-direction: column;
        align-items: center;

        .map1 {
            border-bottom: 1px solid #cccccc;
            padding: 20px;
            width: 100%;
        }

        .map2 {
            border-bottom: 1px solid #cccccc;
            padding: 20px;
            width: 100%;
        }
    }

</style>


